import React from "react"
import Link from "next/link"
import { NotFoundProps } from "../pagesInterfaces/notFound"
import PageState from "../stylesheets/modules/pageState.module.sass"
import Head from "next/head"

const NotFound: React.FC<NotFoundProps> = (): JSX.Element => {
  return (
    <>
      <Head>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css'
          integrity='sha256-YLGeXaapI0/5IgZopewRJcFXomhRMlYYjugPLSyNjTY='
          crossOrigin='anonymous'
        />
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.core.min.css'
          integrity='sha256-w1xA5IxJPjR5nxXpZP0vneJCf62zDzyF6AlXlK7rYdk='
          crossOrigin='anonymous'
          media='all'
        />
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css' />
        <script
          async
          id='Typewriter'
          src='https://unpkg.com/typewriter-effect@latest/dist/core.js'
          crossOrigin='anonymous'
        />
      </Head>
      <div className={PageState["pai-nosso"]}>
        <div className={PageState["page-error"]}>
          <i className={`${PageState.fas} ${PageState["fa-exclamation-triangle"]} fas fa-exclamation-triangle`}></i>
          <p className={PageState.msg}>404!</p>
          <p className={PageState.msg}>Page not found.</p>
          <Link href='/home' passHref>
            <a className={PageState.msg}>
              <button className='btn btn-primary'>
                Home <i className='fas fa-link'></i>
              </button>
            </a>
          </Link>
        </div>
      </div>
    </>
  )
}

export default NotFound
